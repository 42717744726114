/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils
   ------------------------
*/

.text-uppercase {
    @include uppercase;
    letter-spacing: .1em;
}

.text-sm {
    font-size: $font-size-sm;
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}


.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba(theme-color('primary'), .1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-inherit {
    color: inherit;
}

/*
    Rounded corners - lg, sm
   ------------------------
*/

.rounded-lg {
    border-radius: $border-radius-lg;
}

.rounded-sm {
    border-radius: $border-radius-sm;
}

.rounded-xl {
    border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix} {
            border: $border-width solid $border-color !important;
        }
    }
}

/*
    Opacity helpers - .opacity-[1-9]
    ------------------------
*/


$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
    .opacity-#{$current-opacity} {
        opacity: #{$current-opacity/10};
    }
}

/*
    Z-index helpers - .z-index-[10-50]
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
    .z-index-#{$current-z-index} {
        z-index: #{$current-z-index};
    }
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

$spacing: .1em .2em .3em .4em .5em;

@each $current-spacing in $spacing {
    $i: index($spacing, $current-spacing);

    .letter-spacing-#{$i} {
        letter-spacing: #{$current-spacing};
    }
}

/*
     Colours and backgrounds
    ------------------------
*/


@each $color,
$value in $theme-colors {
    .text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    a.text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    .bg-#{$color}-light {
        background-color: theme-color-level($color, -11);
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }
}

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: theme-color-level($color, -11);
    }
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                background: #fff;
            }
        }
    }

    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            &::after {
                transition: $transition-fade;
                opacity: 0;
            }
        }
    }

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix}:hover {
        @include media-breakpoint-up($next) {
            &::after {
                opacity: .4;
            }
        }
    }

    .light-overlay#{$infix}-0,
    .overlay-hover-light#{$infix}-0:hover {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .5;
                background: #000;
                z-index: 30;
            }
        }
    }
    .primary-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .4;
                background: darken($brand-primary, 30%);
                z-index: 30;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .overlay-text {
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }
}

/*
     Other
    ------------------------
*/

.overflow-visible {
    overflow: visible !important;
}

.shadow-0 {
    box-shadow: none !important;
}

.shadow-inner {
    box-shadow: inset $box-shadow !important;
}

.bg-cover {
    background-size: cover !important;
}

.bg-center {
    background-position: center center !important;
}

.index-forward {
    position: relative;
    z-index: 99;
}

.border-width-md {
    border-width: 2px !important;
}

.reset-anchor {
    color: inherit;
    transition: all 0.3s;
    &:hover, &:focus {
        text-decoration: none;
    }
}

.text-gray {
    color: #d2d2d2 !important;
}

.bg-none {
    background: none !important;
}

.bg-darker {
    background: darken($dark, 2%) !important;
}

.text-xl {
    @include media-breakpoint-up(lg) {
        font-size: 4.5rem !important;
    }
}
.text-serif {
    font-family: $font-family-serif;
    font-style: italic;
}


.list-check {
    li {
        padding-left: 1.5rem;
        position: relative;
        list-style: none;
        &::before {
            content: '\f058';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            color: $brand-primary;
            font-family: 'Font Awesome\ 5 Free';
            font-weight: 900;
        }
    }
}

.text-shadow {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.bg-fixed {
    background-attachment: fixed !important
}

.contact-img {
    height: 20rem;
}


.half-page-banner {
    min-height: 20rem;
}

.circle-progress {
    position: relative;

    &-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.scroll-top {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brand-primary;
    color: #fff;
    transition: all 0.3s;
    position: fixed;
    right: 1rem;
    bottom: -4rem;
    z-index: 9999;

    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        background: darken($brand-primary, 10%);
    }

    &.visible {
        bottom: 1rem;
    }
}


.drop-caps::first-letter {
    font-size: 2.9em;
    font-weight: $font-weight-bold;
    color: $brand-primary;
    line-height: 1;
    padding-right: 0.3rem;
    float: left;
}

@each $color,
$value in $theme-colors {
    .drop-caps-#{$color} {
        &::first-letter {
            color: $value;
        }
    }
}
