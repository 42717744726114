.owl-nav-custom {
    perspective: 1000px;
    .owl-nav {
        position: absolute;
        top: 50%;
        left: 2.5rem;
        width: calc(100% - 5rem);
        height: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .owl-prev, .owl-next {
            width: 3.5rem;
            height: 3.5rem;
            background: rgba(0, 0, 0, .5) !important;
            line-height: 3.3rem !important;
            transition: all 0.3s;
            outline: none;
            text-align: center;
            color: #fff;
            position: relative;
            border-radius: 0;

            &.disabled {
                opacity: 0;
                z-index: -1;
            }

            span {
                color: #fff;
                font-size: 2.5rem;
            }

            &:hover {
                background: $brand-primary !important;
            }
        }

        .owl-prev, .owl-next {
            &::before {
                content: 'Prev';
                width: 5rem;
                height: 3.5rem;
                position: absolute;
                top: 0;
                right: -5rem;
                background: rgba(0, 0, 0, .8);
                line-height: 3.3rem;
                transition: all 0.3s;
                text-align: center;
                transform: rotateY(90deg);
                transform-origin: top left;
            }

            &:hover {
                &::before {
                    transform: none;
                }
            }
        }

        .owl-next {
            &::before {
                content: 'Next';
                right: auto;
                left: -5rem;
                transform-origin: top right;
            }
        }
    }

    .owl-dots {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &.hero-slider {
        .owl-dots {
            .owl-dot {
                span {
                    background: #fff;
                    width: 14px;
                    height: 14px;
                }

                &.active {
                    span {
                        background: none;
                        border: 4px solid $brand-primary;
                    }
                }
            }
        }
    }
}



.countdown {
    display: flex;

    span {
        font-size: $h1-font-size;
        font-weight: $font-weight-bold;
    }

    .label {
        display: block;
        font-size: 1rem;
        font-family: $font-family-serif;
        font-weight: 500;
    }

    .item {
        padding: 0 1rem;
    }
}
