/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/

.text-small {
    font-size: 0.9rem !important;
}

section {
    overflow-x: hidden;
}

.bg-primary-shade {
    background: darken($brand-primary, 10%);
}

@each $color,
$value in $theme-colors {
    .bg-#{$color}-shade {
        background: darken($brand-primary, 5%);
    }
}

/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/
.navbar {
    z-index: 999;
}


.navbar-toggler {
    outline: none !important;
    border: none;
}

.navbar-toggler:focus {
    border: none;
}

.navbar-toggler span {
    width: 25px;
    margin: 6px auto;
    display: block;
    height: 2px;
    background: #333;
    transition: all 0.15s;

    &:first-of-type {
        transform-origin: bottom left;
        width: 14px;
    }
    &:nth-of-type(2) {
        width: 20px;
    }
    &:last-of-type {
        transform-origin: top left;
        width: 16px;
    }
}

.navbar-toggler.active span:first-of-type {
    transform: rotate(45deg) translate(-1px);
    width: 24.5px !important;
}

.navbar-toggler.active span:last-of-type {
    width: 24.5px !important;
    transform: rotate(-45deg) translate(-1.5px);
}

.navbar-toggler.active span:nth-of-type(2) {
    opacity: 0;
}

.navbar {
    .dropdown-menu {
        @include media-breakpoint-up(lg) {
            display: block;
        }
        visibility: hidden;
        opacity:0;
        transform: translateY(0.5rem);
        transition:.2s ease all;
    }
    .dropdown-menu.show {
        display: block;
        visibility: visible;
        opacity:1;
        transform: translateY(0px);
        transition:.2s ease all;
    }
}

.navbar {
    @include media-breakpoint-down(md) {
        position: fixed !important;
        background: #fff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

        .nav-link {
            color: $dark !important;

            &:hover, &:focus, &.active {
                color: $brand-primary !important;
            }
        }
    }
}


/*
*
* ==========================================
* HERO
* ==========================================
*
*/
.hero-slide, .hero {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
        display: block;
    }
}

.hero-slide {
    @include media-breakpoint-up(lg) {
        min-height: 45rem;
    }
}

.hero-home {
    @include media-breakpoint-up(lg) {
        min-height: 45rem;
    }
}
